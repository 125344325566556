<template>
  <div>
    <HomePage/>
  </div>
</template>

<script>
import HomePage from '../components/HomePage.vue'

  export default {
    name: 'Home',

    components: {
      HomePage
    },
  }
</script>
