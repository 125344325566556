import { render, staticRenderFns } from "./BooksInspo.vue?vue&type=template&id=182bb70c&scoped=true&"
import script from "./BooksInspo.vue?vue&type=script&lang=js&"
export * from "./BooksInspo.vue?vue&type=script&lang=js&"
import style0 from "./BooksInspo.vue?vue&type=style&index=0&id=182bb70c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182bb70c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
installComponents(component, {VCarousel})
