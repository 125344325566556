<template>
    <div>
        <Log/>
    </div>
</template>

<script>
import Log from '../components/Log.vue'
    export default {
        name : "JournalLog",
        components: {
            Log,
        },
    }
</script>

<style scoped>

</style>